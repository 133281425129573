@media (max-width: 420px) {
  .right-side .about{
    display: none;
  }
  .right-side .price{
    font-size: 1.1rem;
  }
  .left-side h2{
    font-size: 1rem;
  }
  .left-side h2 span{
    font-size: 1.4rem;
  }
}